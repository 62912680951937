import * as React from 'react';
import { Link, FormattedMessage } from 'gatsby-plugin-intl';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <>
    <Helmet>
      <body className="error-404 bkg-color3" />
    </Helmet>
    <Layout>
      <SEO title="404: Not found" titleOnglet="404: Not found" />
      <section className="section content">
        <h1 className="title is-1">
          <FormattedMessage id="notfound.description" />
        </h1>
        <Link to="/" className="button is-transparent">
          <FormattedMessage id="go_back" />
        </Link>
      </section>
    </Layout>
  </>
);

export default NotFoundPage;
